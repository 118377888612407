import { IForm } from '@/base-ui/form'

export const modalConfig: IForm = {
  formItems: [
    {
      field: 'password',
      type: 'password',
      label: '新密码',
      placeholder: '请输入要修改的密码'
    }
  ],
  colLayout: { span: 24 },
  labelWidth: '80px'
}
