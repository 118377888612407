
import { defineComponent, ref } from 'vue'
import { ElNotification } from 'element-plus'
import LocalCache from '@/utils/cache'
import { BASE_URL } from '@/service/request/config'

export default defineComponent({
  emits: ['uploadavatar'],
  setup(props, { emit }) {
    const dialogVisible = ref(false)
    const token = LocalCache.getCache('token')
    const headers = { Authorization: token }
    const action = BASE_URL + '/upload/avatar'

    const handleSuccess = (res: any) => {
      ElNotification({
        title: '上传成功',
        message: res.message,
        type: 'success'
      })
      emit('uploadavatar')
    }
    return {
      headers,
      action,
      dialogVisible,
      handleSuccess
    }
  }
})
