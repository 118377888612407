import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-513afb9c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main" }
const _hoisted_2 = { class: "page-info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nav_menu = _resolveComponent("nav-menu")!
  const _component_el_aside = _resolveComponent("el-aside")!
  const _component_nav_header = _resolveComponent("nav-header")!
  const _component_el_header = _resolveComponent("el-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_container, { class: "main-content" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_aside, {
          width: _ctx.isCollapse ? '60px' : '210px'
        }, {
          default: _withCtx(() => [
            _createVNode(_component_nav_menu, { isCollapse: _ctx.isCollapse }, null, 8, ["isCollapse"])
          ]),
          _: 1
        }, 8, ["width"]),
        _createVNode(_component_el_container, { class: "page" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_header, { class: "page-header" }, {
              default: _withCtx(() => [
                _createVNode(_component_nav_header, { onFoldChange: _ctx.handleFoldChange }, null, 8, ["onFoldChange"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_main, { class: "page-content" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_router_view, null, {
                    default: _withCtx(({ Component }) => [
                      _createVNode(_Transition, {
                        name: "fade-transform",
                        mode: "out-in"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}