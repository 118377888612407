
import { defineComponent, computed, ref, nextTick } from 'vue'
import { useStore } from '@/store'
import Password from './page/update-password.vue'
import Avatar from './page/upload-avatar.vue'
import LocalCache from '@/utils/cache'

export default defineComponent({
  components: {
    Password,
    Avatar
  },
  setup() {
    const store = useStore()
    const name = computed(() => store.state.login.userInfo.name)
    const ts = ref(new Date().getTime())
    const avatar = computed(
      () => store.state.login.userInfo.avatarUrl + '?ts=' + ts.value
    )

    const handleExit = () => {
      LocalCache.deleteCache('token')
      window.location.reload()
    }
    const passwordRef = ref<InstanceType<typeof Password>>()
    const handleUpdatePassWord = () => {
      if (passwordRef.value) {
        passwordRef.value.dialogVisible = true
      }
    }

    const avatarRef = ref<InstanceType<typeof Avatar>>()
    const updateAvatar = () => {
      ts.value = new Date().getTime()
    }
    const handleUploadAvatar = () => {
      if (avatarRef.value) {
        avatarRef.value.dialogVisible = true
      }
    }

    const uploadavatar = () => {
      nextTick(() => {
        updateAvatar()
      })
    }

    return {
      name,
      avatar,
      handleExit,
      passwordRef,
      avatarRef,
      handleUpdatePassWord,
      handleUploadAvatar,
      uploadavatar
    }
  }
})
