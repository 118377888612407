
import { defineComponent, ref } from 'vue'
import { useStore } from '@/store'

import { modalConfig } from '../config/modal.config'
import YhForm from '@/base-ui/form'
import LocalCache from '@/utils/cache'
import { ElMessage } from 'element-plus'

export default defineComponent({
  components: {
    YhForm
  },
  setup() {
    const dialogVisible = ref(false)
    const formData = ref<any>({})

    // 点击确定按钮的逻辑
    const store = useStore()
    const handleConfirmClick = () => {
      dialogVisible.value = false
      // 修改密码
      const user = LocalCache.getCache('userInfo')
      const result = store.dispatch('system/updatePassWord', {
        id: user.id,
        password: { ...formData.value }
      })
      result
        .then((res) => {
          ElMessage({
            showClose: true,
            message: res.message,
            type: 'success'
          })
        })
        .catch(() => {
          ElMessage({
            showClose: true,
            message: '修改密码失败！',
            type: 'error'
          })
        })
    }
    return {
      modalConfig,
      formData,
      handleConfirmClick,
      dialogVisible
    }
  }
})
