import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1f3dad10"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nav-header" }
const _hoisted_2 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_yh_breadcrumb = _resolveComponent("yh-breadcrumb")!
  const _component_userInfo = _resolveComponent("userInfo")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("i", {
      class: _normalizeClass(["fold-menu", _ctx.isFold ? 'el-icon-s-unfold' : 'el-icon-s-fold']),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleFoldClick && _ctx.handleFoldClick(...args)))
    }, null, 2),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_yh_breadcrumb, { breadcrumbs: _ctx.breadcrumbs }, null, 8, ["breadcrumbs"]),
      _createVNode(_component_userInfo)
    ])
  ]))
}