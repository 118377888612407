
import { defineComponent, ref } from 'vue'
import NavMenu from '@/components/nav-menu'
import NavHeader from '@/components/nav-header'

export default defineComponent({
  components: {
    NavMenu,
    NavHeader
  },
  setup() {
    const isCollapse = ref(false)
    const handleFoldChange = (isFlod: boolean) => {
      isCollapse.value = isFlod
    }
    return {
      isCollapse,
      handleFoldChange
    }
  }
})
