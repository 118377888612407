import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "nav-breadcrumb" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item")!
  const _component_el_breadcrumb = _resolveComponent("el-breadcrumb")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_breadcrumb, { "separator-class": "el-icon-arrow-right" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbs, (item) => {
          return (_openBlock(), _createBlock(_component_el_breadcrumb_item, {
            key: item.name,
            to: { path: item.path }
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.name), 1)
            ]),
            _: 2
          }, 1032, ["to"]))
        }), 128))
      ]),
      _: 1
    })
  ]))
}